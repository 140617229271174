import $ from 'jquery';
import {imgChageGeneral} from './_inc/function';

function menu(){
  const menu = $('.Hamberger');
  const nav = $('.NavSp');
  const body = $('body');
  const ac = $('.NavMain__underList--acodion');
  const acd = nav.find('.Acodion');
  const entry = $('.Entry');
  const emenu = $('.entryMenu');
  menu.on('click',function(){
    if(entry.hasClass('-active')){
      active(entry,emenu,body);
    }
    active(menu,nav,body);
  });
  $(document).on('click touched','.MenuBack', function(){
    modalClick(menu,nav);
  });
  $(window).on('resize',function(){
    const disentry = $('.NavSp').css('display');
    resizable(menu,nav,ac,acd,disentry,body);
    resizable(entry,emenu,ac,acd,disentry,body);
  });
  ac.on('click',function(){
    const entry = $('.NavSp').css('display');
    slides($(this),entry);
  });
}

function entry(){
  const body = $('body');
  const entry = $('.Entry');
  const menu = $('.entryMenu');
  const ham = $('.Hamberger');
  const nav = $('.NavSp');
  entry.on('click',function(){
    if(ham.hasClass('-active')){
      active(ham,nav,body);
    }
    active(entry,menu,body);
  });
}

function active(e,n,b){
  if(e.hasClass('-active')){
    e.removeClass('-active');
    n.removeClass('-active');
    b.removeClass('-active');
    $('.MenuBack').remove();
  }else {
    b.append('<div class="MenuBack"></div>');
    b.addClass('-active');
    e.addClass('-active');
    n.addClass('-active');
  }
}

function modalClick(m,n){
    m.removeClass('-active');
    n.removeClass('-active');
    b.removeClass('-active');
    $('.MenuBack').remove();
}

function slides(t,e){
  if(e == 'block'){
    let d = t.next('.Acodion');
    let dc = d.css('display');
    if(dc == 'block'){
      t.removeClass('-active');
      d.slideUp('fast');
    }else{
      t.addClass('-active');
      d.slideDown('fast');
    }
  }
}

function returnTop(){
  const button = $('.ScrollTop');
	$(window).on('scroll',function(){
		btnFade($(this),button);
	});
	returns(button);
}

function btnFade(e,b){
  if(e.scrollTop() > 100){
    b.fadeIn();
  }else{
    b.fadeOut();
  }
}

function returns(b){
  b.on('click',function(){
		$('html,body').animate( {scrollTop:0},'slow');
		return false;
	});
}

function resizable(m,n,a,c,e,b){
  if(e == 'none'){
    m.removeClass('-active');
    n.removeClass('-active');
    a.removeClass('-active');
    c.css('display','none');
    b.removeClass('-active');
    $('.MenuBack').remove();
  }
}

function copyright(){
  const thisYear = $('.Copyright').children('span');
  const myDate = new Date();
  const myYear = myDate.getFullYear();
  thisYear.append(myYear);
}

function changeImg(){
	const setElem = $('.switch');
	const pcName = '_pc'
	const spName = '_sp'
	const replaceWidth = 768;

	setElem.each(function(){
		let $this = $(this);
		function imgSize(){
			let windowWidth = parseInt($(window).width());
			if(windowWidth >= replaceWidth) {
				$this.attr('src',$this.attr('src').replace(spName,pcName)).css({visibility:'visible'});
			} else if(windowWidth < replaceWidth) {
				$this.attr('src',$this.attr('src').replace(pcName,spName)).css({visibility:'visible'});
			}
		}
		$(window).resize(function(){imgSize();});
		imgSize();
	});
}




$( ()=>{
  menu();
  //entry();
  returnTop();
  copyright();
  changeImg();
  imgChageGeneral();
});

$(window).resize(function(){
  imgChageGeneral();
})
