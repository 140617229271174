import $ from 'jquery';

export function imgMap(){
  const m = new Map([
    ['img', $('img')],
    ['bread', $('.Bread')],
    ['spmenu', $('.SpMenu')]
  ]);
  return m;
}

export function formMap(){
  let m = new Map([
    ['btn', $('.-form-btn')],
    ['positionKey', $('.-holiday')]
  ]);
  return m
}
