import $ from 'jquery';
import {imgMap, formMap} from './variable';
import {imageChange} from './class';

export function imgkeys(timing){
  let obj = timing.css('display');
  let key = obj === 'none' ? true : false;
  return key
}

export function imgChageGeneral(){
  let obj = imgMap();
  new imageChange(obj,obj.get('bread')).changes();
  new imageChange(obj,obj.get('spmenu')).changesM();
}

export function formBtn(){
  let scrolls = $(window).scrollTop(),
      btn = formMap().get('btn'),
      limit = 100;
  if(scrolls > limit){
    btn.addClass('is-active');
  }else{
    btn.removeClass('is-active');
  }
}

export function statics(){
  let timing = imgMap(),
      pointer = formMap().get('positionKey').offset().top,
      key = imgkeys(timing.get('spmenu'));
  if(!key){
    if(pointer < $(window).scrollTop()){
      formMap().get('btn').addClass('-static');
    }else{
      formMap().get('btn').removeClass('-static');
    }
  }
}
