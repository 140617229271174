import $ from 'jquery';
import {imgkeys} from './function';


export class imageChange{
  constructor(obj,timing){
    this.obj = obj;
    this.timing = timing;
  }
  changes(){
    let key = imgkeys(this.timing);
    $.each(this.obj.get('img'),function(){
      let data = $(this).data('img');
      if(data == 'small'){
        if(key){
          $(this).attr('src',$(this).attr('src').replace('pc','sp'));
        }else{
          $(this).attr('src',$(this).attr('src').replace('sp','pc'));
        }
      }
    });
  }
  changesM(){
    let key = imgkeys(this.timing);

    $.each(this.obj.get('img'),function(){
      let data = $(this).data('img');
      if(data == 'medium'){
        if(!key){
          $(this).attr('src',$(this).attr('src').replace('pc','sp'));
        }else{
          $(this).attr('src',$(this).attr('src').replace('sp','pc'));
        }
      }
    });
  }
}
